@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "ARCO";
    src: url(assets/fonts/ARCO.ttf);
}

@font-face {
    font-family: "ARCO OSX";
    src: url(assets/fonts/ARCO\ for\ OSX.otf);
}

@font-face {
    font-family: "Explorer";
    src: url(assets/fonts/Explorer\ Free.otf);
}


@font-face {
    font-family: "MohrRounded-SemiBold";
    src: url(assets/fonts/MohrRoundedAlt-SemiBold.ttf);
}

@font-face {
    font-family: "MohrRounded-Bold";
    src: url(assets/fonts/MohrRoundedAlt-Bold.ttf);
}

@font-face {
    font-family: "MohrRounded-Heavy";
    src: url(assets/fonts/MohrRoundedAlt-Black.ttf);
}

@font-face {
    font-family: "MohrRounded-Medium";
    src: url(assets/fonts/MohrRoundedAlt-Medium.ttf);
}

.font-morh-m {
    font-family: MohrRounded-Medium;
}

.font-morh-b {
    font-family: MohrRounded-Bold;
}

.font-morh-h {
    font-family: MohrRounded-Heavy;
}

.font-arco {
    font-family: ARCO;
}

.font-morh-sb {
    font-family: MohrRounded-SemiBold;
}

.font-explorer {
    font-family: Explorer;
}

@layer components {
    .app-header li.link {
        @apply hover: opacity-50;
    }
}

.container {
    max-width: 1420px;
}

.title {
    text-shadow: rgb(2 0 75) 7px -1px 0px, rgb(2 0 75) 7.93758px 0.997398px 0px, rgb(2 0 75) 7.7513px 1.97923px 0px, rgb(2 0 75) 7.44406px 2.93018px 0px, rgb(2 0 75) 7.02066px 3.8354px 0px, rgb(2 0 75) 6.48771px 4.68078px 0px, rgb(2 0 75) 5.85351px 5.45311px 0px, rgb(2 0 75) 5.12797px 6.14035px 0px, rgb(2 0 75) 4.32242px 6.73177px 0px, rgb(2 0 75) 3.44941px 7.21814px 0px, rgb(2 0 75) 2.52258px 7.59188px 0px, rgb(2 0 75) 1.55638px 7.84714px 0px, rgb(2 0 75) 0.565898px 7.97996px 0px, rgb(2 0 75) -0.433417px 7.98825px 0px, rgb(2 0 75) -1.42597px 7.87189px 0px, rgb(2 0 75) -2.39627px 7.63269px 0px, rgb(2 0 75) -3.32917px 7.27438px 0px, rgb(2 0 75) -4.21013px 6.80256px 0px, rgb(2 0 75) -5.02539px 6.22459px 0px, rgb(2 0 75) -5.76223px 5.54948px 0px, rgb(2 0 75) -6.40915px 4.78778px 0px, rgb(2 0 75) -6.95606px 3.95136px 0px, rgb(2 0 75) -7.39442px 3.05329px 0px, rgb(2 0 75) -7.71739px 2.10757px 0px, rgb(2 0 75) -7.91994px 1.12896px 0px, rgb(2 0 75) -7.9989px 0.132735px 0px, rgb(2 0 75) -7.95304px -0.865561px 0px, rgb(2 0 75) -7.78307px -1.85035px 0px, rgb(2 0 75) -7.49165px -2.80627px 0px, rgb(2 0 75) -7.08333px -3.71839px 0px, rgb(2 0 75) -6.56447px -4.57249px 0px, rgb(2 0 75) -5.94318px -5.35524px 0px, rgb(2 0 75) -5.22915px -6.05442px 0px, rgb(2 0 75) -4.43352px -6.65912px 0px, rgb(2 0 75) -3.5687px -7.15991px 0px, rgb(2 0 75) -2.6482px -7.54898px 0px, rgb(2 0 75) -1.68637px -7.82024px 0px, rgb(2 0 75) -0.698222px -7.96947px 0px, rgb(2 0 75) 0.300817px -7.99434px 0px, rgb(2 0 75) 1.29516px -7.89446px 0px, rgb(2 0 75) 2.2693px -7.67139px 0px, rgb(2 0 75) 3.20802px -7.32862px 0px, rgb(2 0 75) 4.09668px -6.87148px 0px, rgb(2 0 75) 4.92142px -6.30711px 0px, rgb(2 0 75) 5.66936px -5.64432px 0px, rgb(2 0 75) 6.32883px -4.89346px 0px, rgb(2 0 75) 6.88954px -4.06623px 0px, rgb(2 0 75) 7.34274px -3.17555px 0px, rgb(2 0 75) 7.68136px -2.23532px 0px, rgb(2 0 75) 7.90012px -1.26021px 0px, rgb(2 0 75) 7.9956px -0.265434px 0px;
}

.btn-shadow {
    --tw-shadow: 0px 4px 0px #02004B;
    --tw-shadow-colored: 0px 4px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.modal>div>div {
    bottom: 0 !important;
    height: 100% !important;
    /* align-items: normal !important; */
}

.modal>div>div>div:first-child {
    height: auto !important;
    border: 4px solid !important;
    border-radius: 12px !important;
}

.modal>div>div>div:first-child>div:first-child {
    border-radius: 12px !important;
    background-color: #fff !important;
}